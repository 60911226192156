import { ScaleFade, Table, Tbody, Td, Tr, Text, Box } from '@chakra-ui/react';
import React from 'react';

import { ICallData, TablaTurneroModel } from '../types';

export interface CallsTableProps {
    calls: ICallData[];
    config: TablaTurneroModel;
}

const TriageLevel = ({ element }) => {
    if (element.triageCategory)
        return (
            <Td>
                <ScaleFade in={true} initialScale={0.1}>
                    <Box
                        bg={element.triageCategory.colorCode}
                        borderRadius="10px"
                        color="white"
                        fontSize={23.47}
                        paddingX={5}
                        paddingY={1}
                        width="-webkit-max-content"
                    >
                        <Text>{element.triageCategory.name}</Text>
                    </Box>
                </ScaleFade>
            </Td>
        );

    return null;
};

const getBody = (calls: ICallData[], config: TablaTurneroModel) => {
    return calls.map((el: ICallData) => {
        return (
            <Tr key={el.id}>
                <Td pr="0px">
                    <ScaleFade in={true} initialScale={0.1}>
                        <Text casing="uppercase" fontSize={23.47} fontWeight={700}>
                            {el.patient}
                        </Text>
                    </ScaleFade>
                </Td>
                <TriageLevel element={el} />
                <Td pr="0px">
                    <ScaleFade in={true} initialScale={0.1}>
                        <Text
                            casing="uppercase"
                            color={config.doctorColor}
                            fontSize={21.34}
                            fontWeight={500}
                        >
                            {el.doctor}
                        </Text>
                    </ScaleFade>
                </Td>
                <Td>
                    <ScaleFade in={true} initialScale={0.1}>
                        <Text fontSize={25.6} fontWeight={500} w="180px">
                            {el.place}
                        </Text>
                    </ScaleFade>
                </Td>
            </Tr>
        );
    });
};

const CallsTable = ({ calls, config }: CallsTableProps) => (
    <Table size="lg" variant="simple">
        <Tbody>{getBody(calls, config)}</Tbody>
    </Table>
);

export default CallsTable;
