import { createReducer } from '@reduxjs/toolkit';

import { SET_CONFIG } from '../actions/actions';
import { loadState } from '../utils/localStorage';

const storedState = loadState();

const defaultValues = {
    sector: 1,
    server: '',
    port: '443',
    username: '',
    password: '',
    qos: 2,
    cleanSession: true,
    topic: '/HSI/HOSPITAL_API/PACIENTE_LLAMADO/#',
    protocol: 'ws',
};

const initialState = storedState ? storedState : defaultValues;

/* Force WebSocket Protocol to be selected based on:
 * 1. If client is running on dev on localhost, then force wss.
 * 2. If client is running over https, then use wss,
 * 3. If client is running over http, then use ws,
 */
initialState.protocol =
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
        ? 'ws'
        : window.location.protocol === 'https:'
        ? 'wss'
        : 'ws';

const configReducer = createReducer(initialState, {
    [SET_CONFIG]: (state, action) => action.payload,
});

export default configReducer;
