import { createReducer } from '@reduxjs/toolkit';
import { MqttClient } from 'mqtt';

import {
    ADD,
    CLEAN_CALLS,
    SET_CLIENT,
    SET_CONNECTION_ENDED,
    SET_CONNECTION_STATUS,
} from '../actions/actions';
import { ICallData, IConnectionStatus } from '../types';
import { end } from '../utils/mqtt';

export interface CallsState {
    calls: ICallData[];
    client?: MqttClient;
    connectionStatus?: IConnectionStatus;
}

const initialState: CallsState = {
    calls: [],
    client: undefined,
    connectionStatus: undefined,
};

const filterUniquePlaceCalls = (calls: ICallData[]): ICallData[] =>
    calls.filter((call, index, self) => index === self.findIndex((c) => c.place === call.place));

const filterUniqueCalls = (calls: ICallData[]): ICallData[] =>
    calls.filter((call, index, self) => index === self.findIndex((c) => c.id === call.id));

const olderThat = (date: Date) => (otherCall: ICallData) => otherCall.time > date;

const MAX_CALLS = 5;

const limitCallsSize = (calls: ICallData[]): ICallData[] =>
    calls.length > MAX_CALLS ? calls.slice(0, MAX_CALLS) : calls;

const parseNullPatientName = (call: ICallData): string =>
    call.patient === 'null null' ? 'Paciente temporal' : call.patient;

const callsReducer = createReducer(initialState, {
    [ADD]: (state, action) => {
        const newCall: ICallData = action.payload;

        newCall.patient = parseNullPatientName(newCall);
        const calls = [newCall, ...state.calls]; //el último llamado va arriba de todo
        const uniqueCalls = filterUniqueCalls(calls); //si llegó la misma llamada, quito la vieja
        const uniquePlacesCalls = filterUniquePlaceCalls(uniqueCalls); //quito llamados viejos al mismo consultorio

        state.calls = limitCallsSize(uniquePlacesCalls);
    },
    [CLEAN_CALLS]: (state, action) => {
        state.calls = state.calls.filter(olderThat(action.payload));
    },
    [SET_CLIENT]: (state, action) => {
        state.client = action.payload;
    },
    [SET_CONNECTION_STATUS]: (state, action) => {
        state.connectionStatus = { type: action.payload };
    },
    [SET_CONNECTION_ENDED]: (state) => {
        let client = state.client;

        if (client) end(client);
        state.client = undefined;
    },
});

export default callsReducer;
