import React, { useCallback, useContext } from 'react';
import { Stack, Text, Image, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { useDate } from '../hooks/useDate';
import { EntityConfigContext } from '../context/EntityConfigContext';
import useImage from '../hooks/useImage';
import { ConfigJsonModel } from '../types';

const Layout = ({ children }: any) => {
    const formatDate = useCallback<(input: Date) => string>(
        (date: Date): string => `${format(date, 'hh:mm a')}`,
        [],
    );

    let date = useDate(formatDate);
    const entityConfig: ConfigJsonModel = useContext(EntityConfigContext);
    const [config, setConfig]: any = React.useState(null);
    const { image }: any = useImage(config?.footer?.logo);
    const { image: imageAlternative }: any = useImage(config?.footer?.logoCenter);

    React.useEffect(() => {
        if (entityConfig) {
            setConfig(entityConfig);
        }
    }, [entityConfig]);

    return (
        <Stack bg="#E5E5E5" flex={1} minHeight="100vh" overflow="auto" spacing={8}>
            <Stack flex={1} justifyContent="center" mt="12px" paddingX={12} spacing={8}>
                {children}
            </Stack>
            <Stack
                align="center"
                as={Link}
                bg={entityConfig?.footer?.color}
                direction="row"
                height="104px"
                justify="space-between"
                paddingX={53}
                to="/config"
                width="full"
            >
                <Box alignItems="flex-start" flex={1}>
                    {config?.footer?.logo && <Image alt="logo" src={image} />}
                </Box>

                <Box display="flex" flex={1} justifyContent="center">
                    {config?.footer?.logoCenter && (
                        <Image alt="logo alternative" pt={3} src={imageAlternative} />
                    )}
                </Box>

                <Box display="flex" flex={1} justifyContent="flex-end">
                    <Text color="white" fontSize="2xl" fontWeight={700}>
                        {date}
                    </Text>
                </Box>
            </Stack>
        </Stack>
    );
};

export default Layout;
